import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import React, { useEffect } from 'react';

function ServicesHomeTwo({ className }) {
    const data = [
        {
            id:1,
            title:"Moslashuvchan shartlar",
            content:"Biz sizning byudjetingiz va ehtiyojlaringizga mos keladigan moslashuvchan to'lov shartlarini taklif qilamiz."
        },
        {
            id:2,
            title:"Foydalanish qulayligi",
            content:"Bizning BNPL dasturimizga osongina kirish mumkin va ulardan foydalanish oson. Buyurtma berish jarayoni bir necha daqiqa davom etadi."
        },
        {
            id:3,
            title:"Xavfsizlik va ishonchlilik",
            content:"Biz to'lovlaringizni xavfsiz va ma'lumotlaringizni himoya qilamiz, shunda siz ishonch bilan onlayn xarid qilishingiz mumkin."
        },
        {
            id:4,
            title:"Xavfsiz va ishonchli",
            content:"Mijozlar ma'lumotlari va tranzaktsiyalarini himoya qilish uchun ilg'or xavfsizlik texnologiyalaridan foydalanadi."
        },
        {
            id:5,
            title:"Oddiy ariza berish jarayoni",
            content:"Mijozlarga tez va oson bo'lib to'lash rejalariga murojaat qilish imkonini beradi."
        },
        {
            id:6,
            title:"Real vaqtda hisobni boshqarish",
            content:"Tranzaktsiyalarni kuzatish va mijozlar hisoblarini boshqarish uchun asboblar panelini taqdim etadi."
        },
    ]

    // gsap
    gsap.registerPlugin(ScrollTrigger)

    useEffect(() => {
        const sectionItems = document.querySelectorAll('.section-item');

        sectionItems.forEach((item,index) => {
            gsap.from(item,{
                y:100,
                opacity:0,
                duration:1.5,
                scrollTrigger:{
                    trigger:item,
                    start:"top 180%",
                    toggleActions:"play none none none"
                }
            })
        })
    },[])
    return (
        <>
            <section className={`appie-services-2-area pb-100 ${className}`} id="service">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-6 col-md-8">
                            <div className="appie-section-title">
                                <h3 className="appie-title">Xususiyatlar</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {data.map((item,index) => {
                            return (
                                <div className="col-lg-4 col-md-6 section-item" key={item.id}>
                                    <div
                                        className={`appie-single-service-2 mt-30 wow animated fadeInUp item-${item.id}`}
                                        data-wow-duration="2000ms"
                                        data-wow-delay="200ms"
                                    >
                                        <div className="icon">
                                            <i className="fas fa-bolt" />
                                        </div>
                                        <h4 className="title">{item.title}</h4>
                                        <p>{item.content}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        </>
    );
}

export default ServicesHomeTwo;
