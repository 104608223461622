import gsap from 'gsap';
import React, { useEffect } from 'react';
import heroMan from '../../assets/images/hero-mans.png';
import heroThumb from '../../assets/images/hero/herothumb.png';
import shapeTen from '../../assets/images/shape/shape-10.png';
import shapeEleven from '../../assets/images/shape/shape-11.png';
import shapeTwelve from '../../assets/images/shape/shape-12.png';
import shapeNine from '../../assets/images/shape/shape-9.png';

function HeroHomeTwo() {

    useEffect(() => {
        gsap.from(".appie-hero-thumb-3",{
            opacity:0,
            duration:1.5,
            x:"100",
            delay:2.2,
        })    
    },[])

    return (
        <>
            <section className="appie-hero-area-2 mb-90">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="appie-hero-content-2">
                                <span>NASIYA</span>
                                <h1 className="appie-title">
                                    Nasiya 365 bilan savdolaringizni <span>oshiring</span>
                                </h1>
                                <p>Yangi mijozlarni jalb qiling</p>
                                <form>
                                    <div className="header-form input-box">
                                        <input type="number" placeholder="77 777 77 77" />
                                        <div className='country-code'>+998</div>
                                        <button type="button">
                                            <i className="fal fa-arrow-right" />
                                        </button>
                                    </div>
                                </form>
                                <div className="hero-users">
                                    <img src={heroMan} alt="" />
                                    <span>
                                        30k <span> Fikrlar</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="appie-hero-thumb-3"
                    data-wow-duration="2000ms"
                    data-wow-delay="400ms"
                >
                    <img src={heroThumb} alt="" />
                </div>
                <div className="hero-shape-1">
                    <img src={shapeNine} alt="" />
                </div>
                <div className="hero-shape-2">
                    <img src={shapeTen} alt="" />
                </div>
                <div className="hero-shape-3">
                    <img src={shapeEleven} alt="" />
                </div>
                <div className="hero-shape-4">
                    <img src={shapeTwelve} alt="" />
                </div>
            </section>
        </>
    );
}

export default HeroHomeTwo;
