import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo-2.png';

function FooterHomeTwo() {
    return (
        <>
            <section className="appie-footer-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-about-widget footer-about-widget-2">
                                <div className="logo">
                                    <a href="#">
                                        <img src={logo} alt="" />
                                    </a>
                                </div>
                                <div className="social mt-30">
                                    <ul>
                                        <li>
                                            <a href="#" target='blank_'>
                                                <i className="fa-brands fa-telegram"></i>   
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target='blank_'>
                                                <i className="fa-brands fa-square-instagram"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target='blank_'>
                                                <i className="fa-brands fa-facebook"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a target='blank_' href="#">
                                                <i className="fa-brands fa-youtube"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target='blank_'>
                                                <i className="fa-brands fa-linkedin"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="footer-navigation footer-navigation-2">
                                <h4 className="title">Kompaniya</h4>
                                <ul>
                                    <li>
                                        <a href="#about-us">Biz haqimizda</a>
                                    </li>
                                    <li>
                                        <a href="#">Izohlar</a>
                                    </li>
                                    <li>
                                        <a href="#">TTS</a>
                                    </li>
                                    <li>
                                        <a href="#">Integratsiya</a>
                                    </li>
                                    <li>
                                        <a href="#">Blog</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-navigation footer-navigation-2">
                                <h4 className="title">NASIYA</h4>
                                <ul>
                                    <li>
                                        <Link to="/about-us">Xususiyatlar</Link>
                                    </li>
                                    <li>
                                        <a href="#">Afzalliklar</a>
                                    </li>
                                    <li>
                                        <a href="#">Statistika</a>
                                    </li>
                                    <li>
                                        <a href="#">Video</a>
                                    </li>
                                    <li>
                                        <a href="#">Narxlar</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-widget-info">
                                <h4 className="title">Aloqa uchun</h4>
                                <ul>
                                    <li>
                                        <a href="mailto:sales@al-raqam.com">
                                            <i className="fal fa-envelope" /> sales@al-raqam.com
                                        </a>
                                    </li>
                                    <li>
                                        <a href="tel:+998333138222">
                                            <i className="fal fa-phone" /> +998 33 313-82-22
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fal fa-map-marker-alt" /> Toshkent sh. Yunusobod tumani, Anorzor mahallasi, Oqilota ko'chasi, 26-11. 100099
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://al-raqam.com/">
                                            <i className="fa-solid fa-laptop" />al-raqam.com
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="
                                    footer-copyright
                                    d-flex
                                    align-items-center
                                    justify-content-between
                                    pt-35
                                "
                            >
                                <div className="apps-download-btn">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-apple" /> App store
                                            </a>
                                        </li>
                                        <li>
                                            <a className="item-2" href="#">
                                                <i className="fab fa-google-play" /> Play market
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="copyright-text">
                                    <p>Copyright © 2023 al-raqam. Barcha huquqlar himoyalangan.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FooterHomeTwo;
