import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import React, { useEffect, useState } from 'react';

function PricingHomeTwo() {

    const [toggleSwitch, setSwitchValue] = useState(true);
    const handleSwitch = (e) => {
        e.preventDefault();
        setSwitchValue(!toggleSwitch);
    };

    // gsap
    gsap.registerPlugin(ScrollTrigger)
        
    useEffect(() =>{
        gsap.from(".pricing-one_left",{
            duration:1.5,
            x:-100,
            opacity:0,
            scrollTrigger:{
                trigger:".pricing-one_left",
                start:"top 180%",
                toggleActions:"play none none none"
            }
        })
        gsap.from(".pricing-one_right",{
            duration:1.5,
            x:100,
            opacity:0,
            scrollTrigger:{
                trigger:".pricing-one_right",
                start:"top 180%",
                toggleActions:"play none none none"
            }
        })
        gsap.from(".pricing-one_center",{
            duration:1.5,
            opacity:0,
            scrollTrigger:{
                trigger:".pricing-one_center",
                start:"top 180%",
                toggleActions:"play none none none"
            }
        })
    },[])

    return (
        <>
            <section className="appie-pricing-2-area pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Ta'rifingizni tanlang</h3>
                                <div className="appie-pricing-tab-btn">
                                    <ul
                                        className="nav nav-pills mb-3"
                                        id="pills-tab"
                                        role="tablist"
                                    >
                                        <li className="nav-item" role="presentation">
                                            <a
                                                onClick={(e) => handleSwitch(e)}
                                                className={`nav-link  ${
                                                    toggleSwitch ? 'active' : ''
                                                }`}
                                                href="#"
                                                type="button"
                                                role="tab"
                                            >
                                                Oyiga
                                            </a>
                                        </li>
                                        <li
                                            className={`nav-item ${toggleSwitch ? 'on' : 'off'}`}
                                            role="presentation"
                                        >
                                            <a
                                                onClick={(e) => handleSwitch(e)}
                                                className={`nav-link  ${
                                                    toggleSwitch === false ? 'active' : ''
                                                }`}
                                                href="#"
                                                type="button"
                                                role="tab"
                                            >
                                                Yiliga
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="tab-content" id="pills-tabContent">
                                <div
                                    className={`tab-pane fade ${toggleSwitch ? 'active show' : ''}`}
                                    id="pills-home"
                                    role="tabpanel"
                                    aria-labelledby="pills-home-tab"
                                >
                                    <div className="row justify-content-center">
                                        <div className="col-lg-4 col-md-6">
                                            <div
                                                className="
                                                    pricing-one__single pricing-one_left pricing-one__single_2
                                                    wow
                                                    animated
                                                    fadeInLeft      
                                                "
                                                >
                                                <div className="pricig-heading">
                                                    <h6>Vebsaytlar</h6>
                                                    <div className="price-range">
                                                        249.000 so'm
                                                        <p>/oy</p>
                                                    </div>
                                                    <p>Sozlash 799.000 so'm</p>
                                                </div>
                                                <div className="pricig-body">
                                                    <div className="pricing-btn mt-35">
                                                        <a className="main-btn" href="#">
                                                            Ta'rifni tanlash
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div
                                                className="
                                                    pricing-one__single pricing-one_center pricing-one__single_2
                                                    wow
                                                    animated
                                                    fadeInUp
                                                "
                                            >
                                                <div className="pricig-heading">
                                                    <h6>Mobil ilovalar</h6>
                                                    <div className="price-range">
                                                        449.000 so'm
                                                        <p>/oy</p>
                                                    </div>
                                                    <p>Sozlash 799.000 so'm</p>
                                                </div>
                                                <div className="pricig-body">
                                                    <div className="pricing-btn mt-35">
                                                        <a className="main-btn" href="#">
                                                            Ta'rifni tanlash
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div
                                                className="
                                                    pricing-one__single pricing-one_right pricing-one__single_2
                                                    item-2
                                                    wow
                                                    animated
                                                    fadeInRight
                                                "
                                            >
                                                <div className="pricig-heading">
                                                    <h6>1 + 1</h6>
                                                    <div className="price-range">
                                                        949.000 so'm
                                                        <p>/oy</p>
                                                    </div>
                                                    <p>Sozlash 799.000 so'm</p>
                                                </div>
                                                <div className="pricig-body">
                                                    <div className="pricing-btn mt-35">
                                                        <a className="main-btn" href="#">
                                                            Ta'rifni tanlash
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`tab-pane fade ${
                                        toggleSwitch === false ? 'active show' : ''
                                    }`}
                                    id="pills-profile"
                                    role="tabpanel"
                                    aria-labelledby="pills-profile-tab"
                                >
                                    <div className="row justify-content-center">
                                        <div className="col-lg-4 col-md-6">
                                            <div
                                                className="
                                                    pricing-one__single pricing-one__single_2
                                                    animated
                                                    fadeInLeft
                                                "
                                            >
                                                <div className="pricig-heading">
                                                    <h6>Vebsaytlar</h6>
                                                    <div className="price-range">
                                                        186.700 so'm
                                                        <p>/yil</p>
                                                    </div>
                                                </div>
                                                <div className="pricig-body">
                                                    <div className="pricing-btn mt-35">
                                                        <a className="main-btn" href="#">
                                                            Ta'rifni tanlash
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div
                                                className="
                                                    pricing-one__single pricing-one__single_2
                                                    animated
                                                    fadeInUp
                                                "
                                            >
                                                <div className="pricig-heading">
                                                    <h6>Mobil Ilovalar</h6>
                                                    <div className="price-range">
                                                        336.700 so'm
                                                        <p>/yil</p>
                                                    </div>
                                                </div>
                                                <div className="pricig-body">
                                                    <div className="pricing-btn mt-35">
                                                        <a className="main-btn" href="#">
                                                            Ta'rifni tanlash
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div
                                                className="
                                                    pricing-one__single pricing-one__single_2
                                                    item-2
                                                    animated
                                                    fadeInRight
                                                "
                                            >
                                                <div className="pricig-heading">
                                                    <h6>1 + 1</h6>
                                                    <div className="price-range">
                                                        711.700 so'm
                                                        <p>/yil</p>
                                                    </div>
                                                </div>
                                                <div className="pricig-body">
                                                    <div className="pricing-btn mt-35">
                                                        <a className="main-btn" href="#">
                                                            Ta'rifni tanlash
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default PricingHomeTwo;
