import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import React, { useEffect } from 'react';
import featureThumb from '../../assets/images/features-thumb-2.png';
import shape13 from '../../assets/images/shape/shape-13.png';
import shape14 from '../../assets/images/shape/shape-14.png';
import shape15 from '../../assets/images/shape/shape-15.png';

function FeaturesHomeTwo() {

    // gsap
    gsap.registerPlugin(ScrollTrigger)
    useEffect(() => {
        gsap.from(".appie-features-thumb",{
            x:100,
            opacity:0,
            duration:1.5,
            scrollTrigger:{
                trigger:".appie-features-thumb",
                start:"top 180%",
                toggleActions:"play none none none"
            }
        })
    },[])

    return (
        <>
            <section className="appie-features-area-2 pt-90 pb-100" id="features">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="appie-section-title appie-section-title-2 text-center">
                                <h3 className="appie-title">
                                    Afzalliklari
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-30 align-items-center">
                        <div className="col-lg-6">
                            <div className="appie-features-boxes">
                                <div className="appie-features-box-item">
                                    <h4 className="title">Qulay xarid</h4>
                                    <p>Mijozlaringizga keyinroq toʻlash imkoniyatini berish orqali xaridlarni yanada qulayroq qiling.</p>
                                </div>
                                <div className="appie-features-box-item item-2">
                                    <h4 className="title">Oson integratsiya</h4>
                                    <p>Bizning dasturlarimiz do'koningizga osongina integratsiyalangan bo'lib, tezda moslashuvchan to'lovlarni amalga oshirish imkonini beradi.</p>
                                </div>
                                <div className="appie-features-box-item item-3">
                                    <h4 className="title">Savdoni oshirish</h4>
                                    <p>Mijozlaringizga qo'shimcha to'lov imkoniyatlarini taqdim eting va konversiyalarni oshiring.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div
                                className="appie-features-thumb"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <img src={featureThumb} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="features-shape-1">
                    <img src={shape15} alt="" />
                </div>
                <div className="features-shape-2">
                    <img src={shape14} alt="" />
                </div>
                <div className="features-shape-3">
                    <img src={shape13} alt="" />
                </div>
            </section>
        </>
    );
}

export default FeaturesHomeTwo;
