import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { default as img1, default as img3 } from "../../assets/images/blog/homeblog/blog1cover.png";
import { default as img2, default as img4 } from "../../assets/images/blog/homeblog/blog2cover.png";

function HomeTwoBlog() {
    const data = [
        {
            id:"foydani-yoqotmasdan-chegirmalar-berish",
            title:"Foydani yo'qotmasdan chegirmalar berish?",
            img:img1,
            time:"Mart 10, 2024",
            type:"Saas & App"
        },
        {
            id:"bnpl-xizmatlari-qanday-ishlaydi",
            title:"BNPL xizmatlari: bu nima va u qanday ishlaydi",
            img:img2,
            time:"Mart 14, 2024",
            type:"Saas & App"
        },
        {
            id:"elektron_tijorat",
            title:"Elektron tijorat",
            img:img3,
            time:"Mart 20, 2024",
            type:"Saas & App"
        },
        {
            id:"domenlar_va_veb_sayt_hosting",
            title:"Domenlar va veb-sayt hosting",
            img:img4,
            time:"Mart 22, 2024",
            type:"Saas & App"
        },
    ]

    // gsap
    gsap.registerPlugin(ScrollTrigger)

    useEffect(() => {
        gsap.from(".appie-blog-item", {
            duration:1.5,
            y:100,
            opacity:0,
            scrollTrigger:{
                trigger:".appie-blog-item",
                start:"top 180%",
                toggleActions:"play none none none"
            }
        })
    },[])

    return (
        <>
            <section id='blogs' className={`appie-blog-area pt-90 pb-95`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Blog postlar</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {data.map(d =>{
                            return (
                                <div className="col-lg-3 col-md-6" key={d.id}>
                                    <div
                                        className="appie-blog-item mt-30 wow animated fadeInUp"
                                        data-wow-duration="3000ms"
                                        data-wow-delay="200ms"
                                    >
                                        <div className="thumb">
                                            <img src={d.img} alt="" />
                                        </div>
                                        <div className="content">
                                            <div className="blog-meta">
                                                <ul>
                                                    <li>{d.time}</li>
                                                    <li>
                                                        <a href="#">{d.type}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <h3 className="title">
                                                <Link to={`/blogs/blog/${d.id}`}>
                                                    {d.title}
                                                </Link>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        <div className={`col-12 all-blogs`}>
                            <Link to="/blogs">
                                <button>Yana</button>    
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HomeTwoBlog;