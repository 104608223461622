import React from 'react';

function Navigation() {
    return (
        <>
            <ul>
                <li>
                    <a href="#">Biz haqimizda</a>
                </li>
                <li>
                    <a href="#">Statistika</a>
                </li>
                <li>
                    <a href="#">Narxlar</a>
                </li>
                <li>
                    <a href="#">TTS</a>
                </li>
                <li>
                    <a href="#">Blog</a>
                </li>
            </ul>
        </>
    );
}

export default Navigation;
