import React, { useState } from 'react';

function Faqs({ className,dark }) {
    const [showQues, setQues] = useState(0);
    const [showMore,setShowMore] = useState(false)
    const openQuestion = (value) => {
        if(showQues == value){
            setQues(0)
        }else{
            setQues(value);
        }
    };
    const data = [
        {
            id:1,
            question:"Ilovangizdan foydalanishning qanday afzalliklari bor?",
            answer:"Ushbu ilova investitsiya mahsulotlarini samarali boshqarish, jarayonlarni boshqarish va moliyaviy operatsiyalarni optimallashtirishga yordam beradi."
        },
        {
            id:2,
            question:"Monitoring va boshqa ilovalar uchun qanday ma'lumotlar mavjud?",
            answer:"Siz mahsulotning to'lov miqdorini, to'lov sanasini, balansini va boshqa moliyaviy parametrlarni kuzatishingiz mumkin."
        },
        {
            id:3,
            question:"Ilovangizdan qanday foydalanishim mumkin?",
            answer:"Shunchaki bizning jamoamiz bilan bog'laning va biz sizga chiqindilarni boshqarish ilovamizdan foydalanishni boshlashingizga yordam beramiz."
        },
        {
            id:4,
            question:"Siz foydalanuvchilarni qo'llab-quvvatlaysizmi?",
            answer:"Ha, biz Al-raqam jamoasi doimiy foydalanuvchi yordamini taqdim etamiz va istalgan vaqtda sizga yordam berishga tayyormiz."
        },
        {
            id:5,
            question:"Muammolar bo'lsa, qo'llab-quvvatlash guruhingizga qanday murojaat qilishim mumkin?",
            answer:"Bizning qo'llab-quvvatlash jamoamiz support@example.com elektron pochta manzili va +123456789 telefon orqali mavjud. Shuningdek, bizning veb-saytimizda jonli suhbatni qo'llab-quvvatlash xizmatidan foydalanishingiz mumkin."
        },
        {
            id:6,
            question:"Ilovangizni boshqa dasturlar bilan birlashtirish mumkinmi?",
            answer:"Ha, bizning ilovamiz boshqa dasturiy yechimlar bilan osongina integratsiyalashish imkonini beruvchi moslashuvchan arxitekturaga ega. Biz turli tizimlar bilan integratsiya qilish uchun API-larni taklif qilamiz."
        },
        {
            id:7,
            question:"Ilovangiz muayyan sohalar uchun qanday xususiyatlarni taqdim etadi?",
            answer:"Bizning ilovamiz muayyan sohalarga moslashtirilgan turli xususiyatlarni taklif etadi. Masalan, chakana savdo kompaniyalari uchun biz tovarlar va sotishni hisobga olish funktsiyalarini, ishlab chiqarish korxonalari uchun esa ishlab chiqarish jarayonlari va inventarizatsiyani boshqarish funktsiyalarini taklif qilamiz."
        },
        {
            id:8,
            question:"Sizning arizangiz qanday qo'shimcha xizmatlarni taqdim etadi?",
            answer:"Biz konsalting xizmatlarini, foydalanuvchilarni o'qitishni, biznesingizning o'ziga xos ehtiyojlarini qondirish uchun moslashtirishni va ilova funksionalligiga muntazam yangilanish va qo'shimchalarni taklif etamiz."
        }
    ]
    const handleShowMore = () => {
        setShowMore(prev => !prev)
    }
    const leftData = data.splice(0,4)
    const rightData = data.splice(0,4)
    
    return (
        <>
            <section id="faqs" className={`appie-faq-area pb-95 ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Tez-tez so'raladigan savollar</h3>
                                {/* <p>Different layouts and styles for team sections.</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className={`col-lg-6 item`}>
                            {leftData.map(d => {
                                return (
                                    <div
                                        className="faq-accordion wow fadeInRight mt-30"
                                        data-wow-duration="1500ms"
                                        key={d.id}
                                    >
                                        <div
                                            className="accrodion-grp animated fadeIn faq-accrodion wow"
                                            data-wow-duration="1500ms"
                                            data-grp-name="faq-accrodion"
                                        >
                                            <div
                                                onClick={() => openQuestion(d.id)}
                                                className={`accrodion ${showQues === d.id ? 'active' : ''}`}
                                            >
                                                <div className="accrodion-inner">
                                                    <div className="accrodion-title">
                                                        <h4>{d.question}</h4>
                                                    </div>
                                                    <div
                                                        className="accrodion-content"
                                                        style={{
                                                            display: showQues === d.id ? 'block' : 'none',
                                                        }}
                                                    >
                                                        <div className="inner">
                                                            <p>{d.answer}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className={`col-lg-6 item none991 ${showMore ? "block991" : ""}`}>
                            {rightData.map(d => {
                                return (
                                    <div
                                        className="faq-accordion wow fadeInRight mt-30"
                                        data-wow-duration="1500ms"
                                        key={d.id}
                                    >
                                        <div
                                            className="accrodion-grp animated fadeIn faq-accrodion wow"
                                            data-wow-duration="1500ms"
                                            data-grp-name="faq-accrodion"
                                        >
                                            <div
                                                onClick={() => openQuestion(d.id)}
                                                className={`accrodion ${showQues === d.id ? 'active' : ''}`}
                                            >
                                                <div className="accrodion-inner">
                                                    <div className="accrodion-title">
                                                        <h4>{d.question}</h4>
                                                    </div>
                                                    <div
                                                        className="accrodion-content"
                                                        style={{
                                                            display: showQues === d.id ? 'block' : 'none',
                                                        }}
                                                    >
                                                        <div className="inner">
                                                            <p>{d.answer}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className={`col-lg-12 all-blogs faqs-btn`}>
                            <button onClick={handleShowMore}>Yana</button>
                        </div>
                        <div className="col-lg-12">
                            <div className="faq-text text-center pt-40">
                                <p>
                                    Qidirgan javobingizni topa olmadingizmi?{' '}
                                    <a href="#contact_">Aloqaga chiqing</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Faqs;