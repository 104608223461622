import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Blogs from './components/Blogs';
import Error from './components/Error';
import Loader from './components/Helper/Loader';
import ScrollToTop from './components/Helper/ScrollToTop';
import Hometwo from './components/HomeTwo';
import Blog from './components/SingleBlog/Blog';

function Routes() {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    });
    return (
        <>
            {loading && (
                <div className={`appie-loader ${loading ? 'active' : ''}`}>
                    <Loader />
                </div>
            )}
            <div className={`appie-visible ${loading === false ? 'active' : ''}`}>
                <Router>
                    <ScrollToTop>
                        <Switch>
                            {/* <Route exact path="/" component={HomeOne} /> */}
                            <Route exact path="/" component={Hometwo} />
                            {/* <Route exact path="/home-three" component={HomeThree} />
                            <Route exact path="/home-four" component={HomeFour} />
                            <Route exact path="/home-five" component={HomeFive} />
                            <Route exact path="/home-six" component={HomeSix} />
                            <Route exact path="/home-seven" component={HomeSeven} />
                            <Route exact path="/home-eight" component={HomeEight} />
                            <Route exact path="/home-dark" component={HomeDark} />
                            <Route exact path="/home-rtl" component={HomeRtl} />
                            <Route exact path="/news" component={News} /> */}
                            <Route exact path="/blogs" component={Blogs} />
                            <Route exact path="/blogs/blog/:id" component={Blog} />
                            {/* <Route exact path="/about-us" component={AboutUs} />
                            <Route exact path="/contact" component={Contact} /> */}
                            {/* <Route exact path="/error" component={Error} />
                            <Route exact path="/about-us-another" component={AboutUsTwo} />
                            <Route exact path="/shops" component={Shops} />
                            <Route exact path="/shops/shop-details" component={ShopDetails} /> */}
                            <Route component={Error} />
                        </Switch>
                    </ScrollToTop>
                </Router>
            </div>
        </>
    );
}

export default Routes;
