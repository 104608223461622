import blog1img from "../../assets/images/blog/homeblog/blog1.jpg"
import blog2img from "../../assets/images/blog/homeblog/blog2.png"
// import blog3img from "../../assets/newImages/blogs/blog3img.jpg"
// import blog4img from "../../assets/newImages/blogs/blog4img.jpg"

const contents = [
  {
    id:"foydani-yoqotmasdan-chegirmalar-berish",
    title:"Foydani yo'qotmasdan chegirmalar berish?",
    beforeImg : [
      "Nazariy jihatdan, chegirmalar mijozlarni jalb qiladi va savdoni oshiradi. Amalda - har doim ham emas. Sizning obro'ingiz va foydangizni yo'qotmasdan chegirma orqali qanday qilib ko'proq mijozlarni jalb qilishni aytamiz.",
      "2020 yilda boshlangan koronavirus pandemiyasi odamlar hayotiga jiddiy ta'sir ko'rsatdi, jumladan, ularning pulga bo'lgan munosabatini o'zgartirdi. Aholi daromadlari kamaydi.Savdoning pasayishiga duch kelgan kiyim-kechak va poyabzal ishlab chiqaruvchilari va sotuvchilari iste'molchi xatti-harakatlarining yangi turlariga moslashishga majbur bo'ldilar. Xuddi shu narsa xizmat ko'rsatish sohasiga ham tegishli. Ko'pgina tadbirkorlar o'z faoliyatini Internetga o'tkazdilar, onlayn savdoni boshladilar va etkazib berish va uyga etkazib berish xizmatlarini qo'shdilar. Talabni rag'batlantirish uchun ular tobora ko'proq chegirmalarga murojaat qila boshladilar.",
      "Qaysi chegirmalar eng yaxshi ishlaydi? Sotishni rag'batlantirish va foydani oshirish uchun ulgurji va chakana savdoda qo'llaniladigan chegirmalar logistika deb ataladi. Ko'pincha ular jismoniy shaxslarga qaratilgan. Agar siz uzoq vaqtdan beri savdo sohasida ishlayotgan bo'lsangiz, unda siz ko'plab chegirmalar bilan tanishsiz. Biz logistika chegirmalarining asosiy turlarini topadigan jadvalni tayyorladik, ular qaysi sohalarda qo'llanilishi va qanday ishlashini bilib olasiz.",
    ],
    img:blog1img,
    beforeQuote : [
      "Agar mijoz tovarni olishdan oldin to'lagan bo'lsa yoki kompaniya xizmatlari uchun ma'lum bir davr uchun oldindan to'lagan bo'lsa. Oldindan buyurtma sotuvchiga mijozning puliga tovar sotib olish imkonini beradi, oldindan to'lov sotuvchiga bir vaqtning o'zida kattaroq miqdorni olish imkonini beradi. Birinchi holda, sotuvchi ssudalar va kreditlarsiz ishlaydi, bu aylanma mablag'lar etishmovchiligi mavjud bo'lganda juda muhimdir. Ikkinchisida u uzoq muddatli hamkorlik kafolatini oladi.",
      "Agar foyda kamaysa, kompaniya tabiiy ravishda tejash rejimini yoqadi. Bir qarashda, bu chegirmalar g'oyasiga zid keladi. Ammo ular kompaniya katta aylanmaga ega bo'lsa, berilishi mumkin. Naqd pul olib kelgan mijozlarga chegirma berish ko'pincha bank ekvayring komissiyasini to'lashdan ko'ra foydaliroqdir."
    ],
    quote : "Tadbirkorlar o'zlari chegirmalarni o'ylab topishlari mumkin. Agar ushbu vosita kompaniya uchun ishlayotgan bo'lsa, siz o'zingizning tasavvuringizdan foydalanishingiz va yaratishingiz mumkin.",
    afterQuote : [
      "Ba'zilar har yuzinchi (1000, 2536) mijozga, ba'zilari ma'lum bir ismga ega bo'lgan barcha odamlarga, ba'zilari esa bosh direktorning buvisining tug'ilgan kuni sharafiga chegirmalar beradi. Bunday aktsiyalar mijozlar uchun yoqimli va ko'pincha kutilmagan. Mijozlar buni eslab qolishadi va do'stlari va tanishlariga aytadilar, bu esa kompaniya auditoriyasini oshiradi."
    ]
  },
  {
    id:"bnpl-xizmatlari-qanday-ishlaydi",
    title:"BNPL xizmatlari: bu nima va u qanday ishlaydi",
    beforeImg : [
      "Dunyodagi eng tez rivojlanayotgan xizmatlardan biri bo'lgan BNPL to'lov rejalari Rossiyada mashhur bo'lib bormoqda. Endi ko'plab ruslar pullik xizmatlardan voz kechishga va hozirgi vaqtda o'z xarajatlarini kamaytirishga harakat qilmoqdalar. BNPL tovarlar va xizmatlar uchun ortiqcha to'lovsiz teng qismlarda to'lash imkonini beradi.",
      "Aholining ixtiyorida bo'lgan real daromadlari hech bo'lmaganda o'smaydigan sharoitlarda bu bozor salohiyatga ega. Yirik chakana banklar, bozorlar, elektron to'lov xizmatlari va hatto mikromoliya provayderlari allaqachon unga kelishgan. Va Markaziy bank BNPL kreditmi yoki yo'qmi degan savolni ko'tardi va uni tartibga solish haqida o'yladi.",
      "BNPL xizmatlari nima ? Foizsiz BNPL (Hozir sotib ol, keyinroq toʻlash) dunyodagi eng tez rivojlanayotgan toʻlov usuli hisoblanadi. Juniper Research tadqiqot kompaniyasi ma'lumotlariga ko'ra, 2022 yilda 360 milliondan ortiq kishi tovarlar va xizmatlar uchun shu tarzda to'lagan. 2027 yilga borib, BNPL xizmatlaridan foydalanuvchilar soni 2,5 baravar oshadi - 900 millionga etadi, Juniper bashorat qilmoqda."
    ],
    img:blog2img,
    beforeQuote : [
      "Statista portalining hisob-kitoblariga ko'ra, o'tgan yili mijozlarning 214 milliard dollardan ortiq puli BNPL xizmatlari orqali o'tgan. 2019 yildan beri BNPL tranzaksiyalari hajmi har yili ikki baravar oshdi. Statista tahlilchilari iste'molchilar bu yil BNPL xizmatlari orqali 300 milliard dollar, to'rt yil ichida esa taxminan 600 milliard dollar sarflashini kutishmoqda.",
      "BNPLning xaridorlar orasida jahon miqyosida mashhurligining siri oddiy – xizmat sizga tovar va xizmatlar uchun foizsiz yoki ortiqcha to‘lovsiz bo‘lib-bo‘lib to‘lash imkonini beradi, oilaviy byudjet zarar ko‘rmaydi.",
      "Bu sotuvchilar uchun ham foydalidir: savdo ayirboshlash ko'payadi, to'lovni bo'lish huquqiga ega bo'lgan mijozlar ko'proq pul sarflashadi va yangi to'lov rejasiga kelishadi. Sotuvchilar tovar va xizmatlar uchun to'lovni darhol xizmat ko'rsatuvchi operatordan oladilar. Ikkinchisi sheriklarning komissiyalaridan daromad oladi: chakana sotuvchilar va xizmat ko'rsatuvchi kompaniyalar unga BNPL orqali har bir to'lovning foizini qaytaradilar.",
      "BNPLning o'sishi iqtisodchilar va jurnalistlarni BNPL xizmatlari kredit kartalari qotiliga aylanishi haqida taxmin qilishlariga olib keldi. Millenniallar va zoomerlar klassik kredit mahsulotlariga nisbatan yondashadilar va arzonroq va texnologik jihatdan ilg'or echimlarni afzal ko'rishadi. Va bankirlar o'zlarining kredit mahsulotlarini mijozlar kutganlariga moslashtira boshladilar va ko'pincha kredit kartalarining standart funksiyalariga bo'lib-bo'lib to'lash opsiyasini birlashtirdilar."
    ],
    quote:"BNPL mexanizmi ham oddiy emas: qarz bir nechta teng qismlarga bo'linadi, to'lov qisqa vaqt ichida amalga oshiriladi."
  },
  {
    id:"elektron_tijorat",
    title:"Elektron tijorat",
    beforeImg : [
      "Elektron tijorat nima?",
      "Elektron tijorat (elektron tijorat) - bu elektron tarmoq, birinchi navbatda internet orqali tovarlar va xizmatlarni sotib olish va sotish yoki pul mablag'lari yoki ma'lumotlarni uzatish. Ushbu elektron tijorat operatsiyalari odatda to'rt turga bo'linadi: biznesdan biznesga (B2B), biznesdan iste'molga (B2C), iste'molchidan iste'molchiga yoki iste'molchidan biznesga.",
    ],
    img:blog1img,
    beforeQuote : [
      "So'nggi yigirma yil ichida Amazon va eBay kabi elektron tijorat platformalari onlayn chakana savdoning sezilarli o'sishiga hissa qo'shdi. AQSh aholini ro'yxatga olish byurosi ma'lumotlariga ko'ra, 2011 yilda elektron tijorat umumiy chakana savdoning 5 foizini tashkil etdi. 2020 yilning 2-choragiga kelib, COVID-19 pandemiyasi boshlanganidan so‘ng, chakana savdoning 16,5 foizi elektron tijorat hissasiga to‘g‘ri keldi. O'shandan beri, jismoniy do'konlar qayta ochilganda, u taxminan 15% gacha tushib ketdi.",
      "Elektron tijorat qanday ishlaydi? Elektron tijorat internetdan quvvatlanadi. Mijozlar onlayn-do'konlarga kirish uchun o'z qurilmalaridan foydalanadilar. Ular do'konlar taklif qilayotgan mahsulotlar va xizmatlarni ko'rib chiqishlari va buyurtma berishlari mumkin.",
      "B2C. Ushbu operatsiyalar korxonalar iste'molchilarga mahsulot, xizmatlar yoki ma'lumotlarni sotganda. Biroq, odatda yuk tashish, yetkazib berish va mijozlarga xizmat ko'rsatish bilan shug'ullanadigan vositachilar yoki vositachilar mavjud. Bu atama 1990-yillar oxiridagi dot-com bumi davrida mashhur bo'lgan, o'shanda onlayn-riteylerlar va tovarlar sotuvchilari yangilik edi."
    ],
    quote:"Bugungi kunda Internetda barcha turdagi iste'mol tovarlari sotiladigan son-sanoqsiz virtual do'konlar va savdo markazlari mavjud. Amazon B2C bozorida hukmronlik qiluvchi ushbu saytlar orasida eng taniqli hisoblanadi."
  },
  {
    id:"domenlar_va_veb_sayt_hosting",
    title:"Domenlar va veb-sayt hosting: Ishga kirishishdan oldin bilishingiz kerak bo'lgan narsalar",
    beforeImg : [
      "Shunday qilib, siz nihoyat veb-sayt yaratishning qiziqarli dunyosiga kirishga qaror qildingiz. Siz blog ochishni, onlayn-do‘kon ochishni yoki professional portfel ochishni rejalashtiryapsizmi, bir narsa aniq: sizga domen va veb-sayt xostingi kerak bo‘ladi. Ammo kuting, internet jargonlari tubiga sho'ng'ishdan va mavjud son-sanoqsiz variantlardan to'lib-toshishdan oldin, keling, domenlar va veb-saytlarni xosting sirlarini ochishga bir oz vaqt ajrataylik.",
      "Ushbu maqolada biz sizga raqamli sayohatni boshlashdan oldin bilishingiz va ko'rib chiqishingiz kerak bo'lgan hamma narsani tushuntirib, asosiy tushunchalar bilan tanishib chiqamiz. Har kuni ko'rib chiqayotgan veb-saytlaringiz ortida yotgan sirlarni ochishga tayyor bo'ling.",
    ],
    img:blog2img,
    beforeQuote : [
      "Domen - bu internetdagi veb-saytni aniqlaydigan yagona manzil. U veb-saytingizning raqamli identifikatori bo'lib, foydalanuvchilarga kontentingizga osongina kirish imkonini beradi. WordPress saytingiz uchun domen tanlashda uning brendingiz yoki joyingizga mosligini hisobga olish muhimdir. Misol uchun, agar veb-saytingiz fotografiya haqida bo'lsa, 'besttraveldeals.com' dan ko'ra 'photolens.com' kabi domen ko'proq mos keladi. Bundan tashqari, eslab qolish va yozish oson bo'lgan domenni tanlash foydalidir, chunki u foydalanuvchi tajribasini yaxshilaydi. Domenlarni GoDaddy yoki Namecheap kabi turli domen registratorlari orqali ro'yxatdan o'tkazish mumkinligini yodda tuting.",
      "Veb-sayt hosting veb-saytingiz uchun asosiy element hisoblanadi. Bu tashrif buyuruvchilarga kirish imkonini beruvchi serverda veb-saytingiz fayllarini saqlash va xizmat ko'rsatishni o'z ichiga oladi. Wordpress domenlari haqida gap ketganda, veb-sayt hostingi ayniqsa muhimdir, chunki u sizning WordPress veb-saytingiz butun dunyo bo'ylab foydalanuvchilar uchun ochiq bo'lishini ta'minlaydi.",
      "Xosting xizmatlarining har xil turlari mavjud, masalan, bir nechta veb-saytlar serverdagi resurslarni baham ko'radigan umumiy hosting yoki siz serverdan eksklyuziv foydalana oladigan maxsus server hostingi. To'g'ri hosting provayderi va rejasini tanlash veb-saytning optimal ishlashi va ishonchliligi uchun juda muhimdir. Xosting xizmatini tanlashda server tezligi, ish vaqti, xavfsizlik xususiyatlari, mijozlarni qo'llab-quvvatlash va kengaytirilishi kabi omillarni hisobga oling."
    ],
    quote:"To'g'ri tanlangan domen sizning brend identifikatoringizni aniqlashga yordam beradi va qidiruv tizimining ko'rinishini yaxshilaydi.",
    afterQuote:["Sekin yuklash vaqtlari va tez-tez uzilishlar tashrif buyuruvchilarni haydab chiqarishi va veb-saytingiz obro'siga putur etkazishi mumkin. Qaror qabul qilishdan oldin turli xil variantlarni tadqiq qilish va solishtirish uchun vaqt ajrating. Sizning domeningiz va xosting tanlovingiz veb-saytingiz muvaffaqiyatiga katta ta'sir ko'rsatishi mumkin."]
  },
]

export default contents